<template>
  <div class="box">
    <div class="publish">
      <div class="wrap">
        <span>发布新闻,同步实时动态</span>
        <router-link :to="`/publish/news?type=${props.groupOriented}`" class="button">发布新闻</router-link>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps } from 'vue'
const props = defineProps({
  groupOriented:{
    type:Number,default:1
  }
})
</script>
<style lang="scss" scoped>
.box {
  background: white url('../assets/imgs/top_quick_news.jpeg') no-repeat;
  background-size: 100% 145px;
  width: 260px;
  height: 220px;
  padding-bottom: 30px;
  border-radius: 10px;
  box-shadow: 1px 1px 11px rgb(212 212 212 / 25%);
  .publish {
    padding-top: 160px;
    font-size: 14px;
    .wrap {
      display: flex;
      flex-direction: column;
    }
    .svg-icon {
      width: 40px;
      height: 40px;
    }
    span {
      color: #ccc;
    }
    .button {
      display: inline-block;
      padding: 5px 10px;
      cursor: pointer;
      text-decoration: none;
      border-radius: 30px;
      background: #f0f0f0;
      margin-top: 15px;
      margin-left: 20px;
      margin-right: 20px;
      background-color: #0093ef;
      color: white;
      &:hover {
        background-color: #43abec;
        color: white;
      }
    }
  }
}
</style>
